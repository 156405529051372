<template>
  <a-card :bordered="false">
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="标价">
            <a-input v-decorator="['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="售价">
            <a-input v-decorator="['sales_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="是否售卖">
            <a-switch v-decorator="['is_sale', {valuePropName: 'checked'}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="排序">
            <a-input v-decorator="['serial', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { exhibition_goods_list, exhibition_goods_update } from '@/api/exhibition_goods'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      exhibition_goods_list({ id: this.$route.params.id })
        .then(({ data }) => {
          const list = data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        values.tag_price = Math.round(values.tag_price * 100)
        values.sales_price = Math.round(values.sales_price * 100)
        exhibition_goods_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'rate', 'name', 'code', 'description', 'bonded_unit', 'exhibition_unit', 'tag_price', 'sales_price', 'is_sale', 'total_exhibition_count', 'serial'])
        console.log('formData', formData)
        formData.tag_price = (formData.tag_price / 100).toFixed(2)
        formData.sales_price = (formData.sales_price / 100).toFixed(2)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
